
// @import '~@ctrl/ngx-emoji-mart/picker';
/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


:root{
    font-size: 16px;
}

:host ::ng-deep {
::-webkit-scrollbar-track{
    box-shadow: inset 0 0 5px gray !important;
    border-radius: 10px  !important;
}

::-webkit-scrollbar-thumb{
    background: #bbb  !important;
    border-radius: 10px  !important;
}

::-webkit-scrollbar-thumb:hover{
    background: #bbb  !important;
}
}

body {
    font-family: 'Inter', 'ui-sans-serif', 'system-ui', 'Segoe UI', 'Roboto', 'Helvetica Neue', 'Arial', 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

/* Hide the up and down arrows for number input */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.ng-otp-input-wrapper{
    display: grid;   
    grid-template-columns: repeat(6,1fr);
    justify-items: center;

    .otp-input{
        width: 50px !important;
        height: 50px !important;
    }
}

.ngx-ic-source-image{
    width: 40% !important;
    height: auto !important;
    margin: 0 auto !important;
}

.sb-group{
    flex-wrap: nowrap !important;
}
.sb-circles-dark .sb-wrapper{
    --sb-icon-size: 1em !important;
}

.blog-details, .service-details {
    h3 {
        font-size: 1.17em !important;
        font-weight: bold !important;
    }

    h2{
        font-size: 1.5em !important;
        font-weight: bold !important;
    }

    h1{
        font-size: 2em !important;
        font-weight: bold !important;
    }

    h6{
        font-size: 0.67em !important;
        font-weight: bold !important;
    }

    h5{
        font-size: 0.83em !important;
        font-weight: bold !important;
    }

    h4{
        font-weight: bold !important;
    }

    a{
        color: #698DFA;
        cursor: pointer;
    }

    figure, img{
        margin: 0 auto;
    }

    td{
        border: 1px solid rgb(209 213 219);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    th{
        border: 1px solid rgb(209 213 219);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    ol{
        list-style-type: auto;
        padding-left: 10px;
    }
    ul{
        list-style-type: disc;
        padding-left: 10px;
    }
}

asl-google-signin-button{
    iframe{
        margin: 0 auto !important;
    }
}